























































































































































.layoutBox {
	margin: 0 0.2rem;
	margin-top: 0.2rem;
	font-family: PingFangSC-Regular;
	.old_zichan {
		border-radius: 0.16rem;
		background-color: #FFFFFF;
		padding: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.z_left {
			.zi_name {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-size: 0.28rem;
				font-weight: 550;
			}
			.yu {
				margin-left: -0.23rem;
				color: #6F7A8B;
				font-size: 0.24rem;
				transform: scale(0.83);
			}
		}
		.z_right {
			.inp {
				font-family: PingFangSC-Medium;
				width: 1.68rem;
				height: 0.33rem;
				line-height: 0.33rem;
				font-size: 0.28rem;
				color: #333333;
				border: none;
				font-weight: 550;
				text-align: right;
			}
			input::-webkit-input-placeholder {
			    /* 修改字体颜色 */
			    color: #6F7A8B;
				font-weight: 500;
				font-size: 0.24rem;
			}
		}
	}
	.zhuanhuan {
		margin: 0 auto;
		// padding: 0.18rem;
		background-color: #F7F7FA;
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -0.2rem;
		position: relative;
		z-index: 10;
		img {
			width: 0.42rem;
			height: 0.42rem;
		}
	}
	.new_zichan {
		margin-top: -0.2rem;
		border-radius: 0.16rem;
		background-color: #FFFFFF;
		padding: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.z_left {
			.zi_name {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-size: 0.28rem;
				font-weight: 550;
			}
			.yu {
				margin-left: -0.23rem;
				color: #6F7A8B;
				font-size: 0.24rem;
				transform: scale(0.83);
			}
		}
		.z_right {
			font-family: PingFangSC-Medium;
			font-size: 0.28rem;
			color: #333333;
			font-weight: 550;
			text-align: right;
		}
	}
	.bottom {
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 0;
		.button {
			margin: 0.14rem 0.3rem 0.14rem 0.28rem;
			width: 6.9rem;
			height: 0.88rem;
			line-height: 0.88rem;
			background-color: #fad538;
			color: #333333;
			font-size: 0.32rem;
			text-align: center;
			border-radius: 0.16rem;
			font-weight: 500;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;